/* eslint-disable */
import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";
// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Image from "@components/common/CloudinaryImage";
// import { FaParking } from "react-icons/fa";
// import Title from "@components/common/Title";
// import Text from "@components/common/Text";
// import { InlineWidget } from "react-calendly";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    Oferim una preparació intensiva per a les proves físiques de policies locals,
                    Mossos d'Esquadra i Guàrdia Urbana, amb planificació setmanal per mantenir-te en
                    forma. La nostra filosofia és extreure el màxim rendiment de cada alumne amb
                    trucs, tips i consells.
                </p>

                <ul>
                    <li>🕠Dissabtes: 9:30 - 11:30 h</li>
                </ul>

                <p>
                    Ens centrem en{" "}
                    <strong>millorar la velocitat, potència, força i resistència</strong>, així com
                    en el treball específic per al{" "}
                    <strong>circuit d'agilitat, pres banca i course navette</strong>. Oferim un
                    espai de referència, totalment adaptat a totes les necessitats, al CEM Besòs.
                </p>

                <p>
                    <strong>
                        Ronda de Sant Ramon de Penyafort, s/n, 08930 Sant Adrià de Besòs, Barcelona
                    </strong>
                    .
                </p>

                <p>
                    Disposem de fàcil aparcament. Els nostres entrenaments estan dissenyats per
                    portar-vos al vostre estat òptim, amb un gran percentatge d'aprovats cada any!
                </p>

                <p>
                    Supervisat per la nostra coordinadora <strong>Helena Moral</strong>.{" "}
                    <strong>Places limitades!</strong>
                </p>

                <p>
                    <strong>
                        Els packs estan limitats a 30 dies de durada, és a dir, es poden utilitzar
                        fins a 1 mes després de la seva compra.
                    </strong>
                </p>

                <p>
                    <strong>
                        Cada sessió s'ha de reservar i/o cancelar amb un mínim de 48 hores
                        d'antelació.
                    </strong>
                </p>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Vine a entrenar amb nosaltres a Sant Adrià del Besòs!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
